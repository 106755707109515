import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Items, TremendousProduct } from 'src/app/models/tremendous.product.model';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-catalogue-card',
  templateUrl: './catalogue-card.component.html',
  styleUrls: ['./catalogue-card.component.scss']
})
export class CatalogueCardComponent implements OnInit {
  @Output() itemSelected = new EventEmitter<any>();


  constructor(
    public appService: AppService,
    public dialogRef: MatDialogRef<CatalogueCardComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { data: TremendousProduct, view: boolean, multiple: boolean }) { }

  ngOnInit(): void { console.log(this.data); }

  callFunction(data: TremendousProduct) {
    this.itemSelected.emit({ data, isSelected: this.data.multiple });
    this.dialogRef.close();
  }

  isOpen = {
    section1: false,
    section2: false,
    section3: false,
    section4: false
  };

  toggleSection(section: string) {
    this.isOpen[section] = !this.isOpen[section];
  }

  isType() {
    if (this.data?.data?.items[0]?.denomination_type === 'open') {
      return 'variable'
    } else {
      return 'fixed'
    }
  }

  isValueFor() {
    return this.appService.getCurrencySymbol(this.data?.data?.currency_code) + this.data?.data?.min_value + ' - ' + this.appService.getCurrencySymbol(this.data?.data?.currency_code) + this.data?.data?.max_value
  }

}
