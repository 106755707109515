import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateGiftComponent } from './create-gift.component';
import { ShareModule } from '../shareModule/share.module';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { CreateCollectPotComponent } from './components/create-collect-pot/create-collect-pot.component';
import { InvitePeopleComponent } from './components/invite-people/invite-people.component';
import { BuyGiftCardComponent } from './components/buy-gift-card/buy-gift-card.component';
import { CreateCardComponent } from './components/create-card/create-card.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { CreateCatalogueProductAndProceedComponent } from './components/create-catalogue-product-and-proceed/create-catalogue-product-and-proceed.component';
import { ProdigiFormComponent } from './components/prodigi-form/prodigi-form.component';
import { SelectProdigiProductComponent } from './components/select-prodigi-product/select-prodigi-product.component';
import { CollageMakerDialogComponent } from './dialogs/collage-maker-dialog/collage-maker-dialog.component';
import { DonateCharityDialogComponent } from './dialogs/donate-charity-dialog/donate-charity-dialog.component';
import { NotLoginPopupComponent } from './components/create-collect-pot/notLoginPopup/notLoginPopup.component';
import { DesignOwnCardModule } from '../design-own-card/design-own-card.module';
import { CatalogueCardComponent } from './components/create-catalogue-product-and-proceed/catalogue-card/catalogue-card.component';

import { CharityDialogComponent } from './dialogs/charity-dialog/charity-dialog.component';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { PayoutDialogComponent } from './components/buy-gift-card/payout-dialog/payout-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    ShareModule,
    NgxMaterialTimepickerModule,
    DesignOwnCardModule,
    FormsModule,
    MatDialogModule

  ],
  declarations: [
    NotLoginPopupComponent,
    CreateCollectPotComponent,
    InvitePeopleComponent,
    BuyGiftCardComponent,
    SideNavComponent,
    CreateGiftComponent,
    CreateCardComponent,
    CreateCatalogueProductAndProceedComponent,
    ProdigiFormComponent,
    SelectProdigiProductComponent,
    CollageMakerDialogComponent,
    DonateCharityDialogComponent,
    CatalogueCardComponent,
    CharityDialogComponent,
    PayoutDialogComponent,
  ],
  exports: [CommonModule, BuyGiftCardComponent],
  entryComponents: [
    CreateCardComponent,
    CreateCatalogueProductAndProceedComponent,
    DonateCharityDialogComponent,
  ],
})
export class CreateGiftModule { }
