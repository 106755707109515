import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CardDetails } from 'src/app/models/card-detials.model';
import { TremendousProduct } from 'src/app/models/tremendous.product.model';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-cards-details',
  templateUrl: './cards-details.component.html',
  styleUrls: ['./cards-details.component.scss']
})
export class CardsDetailsComponent implements OnInit {

  constructor(public appService: AppService, public dialogRef: MatDialogRef<CardsDetailsComponent>, @Inject(MAT_DIALOG_DATA) public data: TremendousProduct) { }
  ngOnInit(): void {
    console.log(this.data);
  }

  isOpen = {
    section1: false,
    section2: false,
    section3: false,
    section4: false
  };

  toggleSection(section: string) {
    this.isOpen[section] = !this.isOpen[section];
  }

  isTypeVariable() {
    if (this.data?.max_value !== this.data?.min_value) {
      return 'variable'
    } else {
      return 'fixed'
    }
  }

}
