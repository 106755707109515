import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Campaign } from 'src/app/models/campaign.model';
import { ValidationService } from 'src/app/modules/shareModule/controls-msg/validation.service';
import { BuyGiftCardComponent } from '../buy-gift-card.component';

@Component({
  selector: 'app-payout-dialog',
  templateUrl: './payout-dialog.component.html',
  styleUrls: ['./payout-dialog.component.scss']
})
export class PayoutDialogComponent implements OnInit {
  myFormGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<PayoutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { this: BuyGiftCardComponent }
  ) { console.log(this.data); }

  ngOnInit(): void {
    this.myFormGroup = new FormGroup({
      email: new FormControl('', [Validators.required, ValidationService.emailValidator]),
      card_number: new FormControl('', [Validators.required, ValidationService.creditCardNumberValidator]),
      card_exp: new FormControl('', [Validators.required, ValidationService.creditCardExpiryDateValidator]),
      amount: new FormControl('', [Validators.required, Validators.min(1)])
    });
  }


  valueChanges(value: string) {
    const cardExpControl = this.myFormGroup.get('card_exp');
    if (!cardExpControl) return;

    let cardExp = cardExpControl.value.replace(/\D/g, ''); // Remove non-digit characters
    if (cardExp.length > 2) {
      cardExp = `${cardExp.slice(0, 2)}/${cardExp.slice(2, 4)}`;
    }

    cardExpControl.setValue(cardExp, { emitEvent: false });
  }

  submitForm(): void {
    const payload = this.myFormGroup.value;
    console.log('Payload:', payload);
  }

}
