import { NgModule } from '@angular/core';
import { HomeComponent } from './component/home.component';
import { ShareModule } from '../shareModule/share.module';
import { CommonModule } from '@angular/common';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { RouterModule } from '@angular/router';
import { CategoryPipe } from './pipes/category/category.pipe';
import { AboutUsComponent } from './about/about-us/about-us.component';
import { TermsConditionsComponent } from './Terms-Conditions/Terms-Conditions.component';
import { PrivacyPolicyComponent } from './Privacy-policy/Privacy-policy.component';
import { ConnectStripeComponent } from './connect-stripe/connect-stripe.component';
import { FeesComponent } from './fees/fees.component';
import { BrowseAvailableGiftProvidersComponent } from './component/browse-available-gift-providers/browse-available-gift-providers.component';
import { CardsDetailsComponent } from './component/cards-details/cards-details.component';

@NgModule({
  declarations: [
    HomeComponent,
    AboutUsComponent,
    CategoryPipe,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    ConnectStripeComponent,
    FeesComponent,
    BrowseAvailableGiftProvidersComponent,
    CardsDetailsComponent,
  ],
  entryComponents: [BrowseAvailableGiftProvidersComponent],
  imports: [ShareModule, CommonModule, SlickCarouselModule, RouterModule],
})
export class HomeModule {}
