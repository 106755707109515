<form [formGroup]="data.this.form">
  <header>
    <div>
      <h4 class="m-0 font-Caviar">
        Redeem Now {{ this.data.this.form.value.charityAmount }}
      </h4>
      <div
        class="h-italic"
        *ngIf="data?.this?.fromValue?.payoutType === 'catalogue'"
      >
        You can select the gift provider in the next step
      </div>
    </div>
    <button
      type="button"
      class="ml-auto"
      mat-icon-button
      (click)="onClose(false)"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </header>
  <mat-progress-bar *ngIf="false" [mode]="'indeterminate'"></mat-progress-bar>
  <br />

  <label class="labelPadd">
    <ng-container *ngIf="data?.this?.cardVal == 'uploaded'">
      Your CollectaGift Pot collected a total of
      {{
        (campaign?.collectedAmount | currency : campaign?.campaignCurrency) ||
          "--"
      }}.<span *ngIf="campaign?.purchaseCard">
        As you have selected a
        <!-- As you have selected to purchase a card for -->
        {{ 4 | currency : campaign?.campaignCurrency }}</span
      >
      card, this leaves
      {{
        data?.this?.campaign?.collectedAmount - (data?.this?.money || 0) - 4
          | currency : campaign?.campaignCurrency
      }}
      left to redeem.
    </ng-container>

    <ng-container *ngIf="data?.this?.cardVal == 'selected'">
      Your CollectaGift Pot collected a total of
      {{
        (campaign?.collectedAmount | currency : campaign?.campaignCurrency) ||
          "--"
      }}.<span *ngIf="campaign?.purchaseCard">
        As you have selected a
        <!-- {{ data?.this?.money || 0 | currency : campaign?.campaignCurrency }} on the gift and -->
        {{
          data?.this.campaignCardPr | currency : campaign?.campaignCurrency
        }}</span
      >
      card, this leaves
      {{
        data?.this?.campaign?.collectedAmount -
          (data?.this.money || 0) -
          data?.this.campaignCardPr | currency : campaign?.campaignCurrency
      }}
      left to redeem.
    </ng-container>

    <ng-container *ngIf="data?.this?.cardVal == 'freeCard'">
      Your CollectaGift Pot collected a total of
      {{
        (campaign?.collectedAmount | currency : campaign?.campaignCurrency) ||
          "--"
      }}.<span *ngIf="campaign?.purchaseCard">
        As you have selected a free card
        <!-- {{ data?.this?.money || 0 | currency : campaign?.campaignCurrency }} on the gift --> </span
      >, this leaves
      {{
        data?.this?.campaign?.collectedAmount
          | currency : campaign?.campaignCurrency
      }}
      left to redeem.
    </ng-container>
    <!-- -(data?.this.money || 0) -data?.this.campaignCardPr -->
  </label>

  <!-- <div *ngIf="form.get('giftDecisionType').value !== 'decided'">
    <div class="form-group">
      <label class="info h-italic">
        {{ campaignContent?.buyGiftCardPaid?.question3 || "" }}
        <mat-icon
          tooltip="Please enter the name or a brief description of the gift. Upon redeeming the CollectaGift proceeds, we will auto-email all recipients notifying them of the gift selected."
        >
          info
        </mat-icon>
      </label>
      <input
        type="text"
        class="form-control w-50"
        placeholder="Please provide a brief description of the gift"
        formControlName="giftName"
      />
    </div>
  </div> -->

  <div class="mb-4">
    <label for="amount" class="info h-italic">
      {{ campaignContent?.buyGiftCardPaid?.question2IfChooseA || "" }}
      <mat-icon
        tooltip="This amount will be sent to you as an e-voucher from your chosen brand for you to share with the recipient. 
        100% of any leftover amount will be donated automatically to our partner charity Smile Train UK."
      >
        info
      </mat-icon>
    </label>
    <!--question2IfChooseA  -->
    <div class="font-12 mb-2" style="color: red; font-style: italic">
      You can spend the full available amount on the gift. However, if for
      example, you have £10.90 available to spend, you may for example, want to
      spend £10 on the gift, as it's a round number, and donate the remaining
      £0.90 to our partner charity Smile Train. 100% of any donated amount will
      be received by Smile Train UK
    </div>
    <div id="amount" class="form-group icon left">
      <input
        (input)="valueChanges($event.target.value)"
        [readonly]="!data.this.form.get('wantToDonate')?.value"
        type="text"
        maxlength="10"
        class="form-control col-7"
        placeholder="Enter amount you would like to spend on the gift"
        formControlName="giftAmount"
      />
      <span class="text">
        {{ _appService.getCurrencySymbol(campaign?.campaignCurrency) }}
      </span>
    </div>
  </div>
  <!-- left amount  -->
  <ng-container *ngIf="data?.this?.cardVal == 'uploaded'">
    <div style="font-size: 16px; font-weight: 600">
      This leaves
      {{
        data?.this?.campaign?.collectedAmount - (data?.this?.money || 0) - 4
          | currency : campaign?.campaignCurrency
      }}
      left in your CollectaGift Pot
    </div>
  </ng-container>
  <ng-container *ngIf="data?.this?.cardVal == 'selected'">
    <div style="font-size: 16px; font-weight: 600">
      This leaves
      {{
        data?.this?.campaign?.collectedAmount -
          (data?.this.money || 0) -
          data?.this.campaignCardPr | currency : campaign?.campaignCurrency
      }}
      left in your CollectaGift Pot
    </div>
  </ng-container>

  <ng-container *ngIf="data?.this?.cardVal == 'freeCard'">
    This leaves

    {{
      data?.this?.campaign?.collectedAmount
        | currency : campaign?.campaignCurrency
    }}
    left in your CollectaGift Pot
  </ng-container>

  <div>
    <div>
      <div>
        <div class="form-group pt-2">
          <mat-radio-group
            class="orange"
            formControlName="wantToDonate"
            (change)="onWantToDonate($event.value)"
          >
            <br />
            <mat-radio-button [value]="true" class="d-flex">
              <div>
                I would like to become a CollectaGift Charity Champion and
                donate the left over amount of
                <span>{{
                  data?.this?.campaign?.collectedAmount -
                    (data?.this?.money || 0) -
                    data?.this.campaignCardPr
                    | currency : campaign?.campaignCurrency
                }}</span>
                to Smile Train UK
                <a [href]="'https://www.smiletrain.org.uk/'" target="_blank"
                  >Smile Train</a
                >
                . I understand the amount {{ this.campaign.receipientName }}
                will receive as a gift is
                <span></span
                >{{
                  data?.this?.money || 0 | currency : campaign?.campaignCurrency
                }}

                <!-- I would like to become a CollectaGift Charity Champion and donate
                this leftover amount <br />
                to our partner charity
                <a [href]="'https://www.smiletrain.org.uk/'" target="_blank">Smile Train</a> -->
              </div>
              <span class="info">
                <mat-icon
                  tooltip="100% of this donated amount will go directly to Smile Train."
                >
                  info
                </mat-icon>
              </span>
            </mat-radio-button>
            <mat-radio-button [value]="false">
              <ng-container
                *ngIf="data?.this?.cardVal == 'uploaded'; else temp"
              >
                I would prefer to redeem the total available amount of
                {{
                  (campaign?.collectedAmount - 4
                    | currency : campaign?.campaignCurrency) || "--"
                }}
                and not become a CollectaGift Charity Champion.

                <!-- I would like to redeem the full available amount of
                {{
                (campaign?.collectedAmount - 4
                | currency : campaign?.campaignCurrency) || "--"
                }}
                to purchase a gift. -->
              </ng-container>
              <ng-template #temp>
                I would prefer to redeem the total available amount of
                {{
                  (campaign?.collectedAmount - data?.this?.campaignCardPr
                    | currency : campaign?.campaignCurrency) || "--"
                }}
                and not become a CollectaGift Charity Champion.

                <!-- I would like to redeem the full available amount of
                {{
                (campaign?.collectedAmount - data?.this?.campaignCardPr
                | currency : campaign?.campaignCurrency) || "--"
                }}
                to purchase a gift. -->
              </ng-template>
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>
  <br />
  <footer class="text-right">
    <button
      type="submit"
      mat-button
      ButtonUi
      color="c1"
      [disabled]="remainingAmount <= 0"
      (click)="OpenCharityDialog()"
    >
      Continue
    </button>
  </footer>
</form>
