<mat-card
  routerLink="/pages/create-gift"
  [queryParams]="{
    campaignId: (campaign?.inviteLink | campaignInviteId),
    tab: 2
  }"
>
  <div class="header justify-content-between d-flex mobile-crd">
    <div class="w-100 d-flex">
      <div class="icon" [ngClass]="campaign?.category?.name | categoryColor">
        <img
          [src]="campaign?.category?.imgUrl"
          alt=""
          onerror="this.src='https://shmos.s3.amazonaws.com/collectagift/d271fa910a621ebfbeab4aee34df1ffde4c33324.png'"
        />
      </div>

      <div class="heading-text pr-1">
        <span class="text-blue"
          >{{ campaign?.receipientName || "N/a" }}
          <span *ngIf="campaign?.tremendousRewardId"
            >({{ campaign?.tremendousRewardId }})</span
          ></span
        >
        <br />
        <span>{{
          campaign?.purposeOfGift || campaign?.category?.name || "N/a"
        }}</span>
      </div>

      <div class="actions pl-2" *ngIf="actionOptions">
        <div
          class="costom-select"
          *ngIf="showStatus"
          [ngClass]="
            campaign.status == 'active'
              ? 'success'
              : campaign.status == 'dateAchieved'
              ? 'blue'
              : 'red'
          "
        >
          <!-- <div class="mat-select px-3">
          {{campaign?.status | titlecase}}
        </div> -->

          <mat-select
            #select
            (click)="$event.stopPropagation()"
            class="costom-select"
            [disabled]="campaign.status == 'closed'"
            [(ngModel)]="campaign.status"
            (selectionChange)="updateStatus()"
          >
            <mat-option [value]="'active'" [disabled]="isExpried"
              >Open - accepting contributions</mat-option
            >
            <mat-option [value]="'dateAchieved'"
              >Closed - Pending Payout</mat-option
            >
            <mat-option [value]="'closed'" disabled>Paid Out</mat-option>
          </mat-select>
          <mat-icon (click)="$event.stopPropagation(); select.open()"
            >keyboard_arrow_down</mat-icon
          >
        </div>
      </div>
    </div>
    <div class="cpy-lnk-btn w-max">
      <!-- copy card link -->

      <button
        mat-stroked-button
        *ngIf="
          campaign.status == 'closed' && campaign?.templateType != 'freeCard'
        "
        color="primary"
        [disabled]="isPrinting"
        (click)="$event.stopPropagation(); printCard()"
        class="print"
      >
        <!-- *ngIf="
 && campaign.isAmountProcessed" -->
        <div class="d-flex align-items-center">
          <mat-icon class="mr-2" inline="true">cloud_download</mat-icon>
          <!-- Print Card -->
          Download Card
          <mat-spinner
            *ngIf="isPrinting"
            class="d-inline-block ml-2"
            mode="indeterminate"
            [strokeWidth]="1.5"
            [diameter]="15"
          ></mat-spinner>
        </div>
      </button>
      <div>
        <button
          class="no-btn"
          tooltip="Click to copy card link."
          type="button"
          *ngIf="campaign?.finalTemplate"
          (click)="$event.stopPropagation(); copyCardLink()"
        >
          <!-- <img src="assets/images/clipboard.png" alt="" /> -->
          Copy card link
        </button>
      </div>
      <!-- <div class="pt-2" *ngIf="campaign.status == 'closed'">
        *ngIf="campaign?.finalTemplate && campaign.isAmountProcessed"
        <a
          (click)="$event.stopPropagation()"
          href="/digital-e-card/{{ campaign?.inviteLink | campaignInviteId }}"
          target="_blank"
          >View Card</a
        >
      </div> -->
    </div>
  </div>

  <div class="cantent mt-2">
    <div class="text-center">
      <h3>
        {{
          campaign?.collectedAmount || 0
            | currency : campaign?.campaignCurrency || "GBP"
        }}/{{
          campaign?.maxContributionAmountInTotal || 0
            | currency : campaign?.campaignCurrency || "GBP"
        }}
      </h3>
      <span class="text-muted">Contributed So Far</span>
    </div>
  </div>
  <div class="links-container">
    <div class="text-center" (click)="$event.stopPropagation()">
      <a class="wishes" (click)="openWishes(campaign.contributions)"
        >View all wishes</a
      >
    </div>
    <div
      class="text-center"
      (click)="$event.stopPropagation()"
      *ngIf="campaign?.giftSuggestions"
    >
      <a class="wishes" (click)="openPoll(campaign)">View Gift Poll</a>
    </div>
    <div
      class="text-center"
      (click)="$event.stopPropagation()"
      *ngIf="campaign?.contributorGiftIdeas"
    >
      <a class="wishes" (click)="openSuggestion(campaign)"
        >View Gift Suggestions</a
      >
    </div>
    <div class="text-center" (click)="$event.stopPropagation()">
      <a class="wishes" (click)="openEncodes(campaign.contributions)"
        >View all Anecdotes</a
      >
    </div>
  </div>

  <footer class="justify-between">
    <div class="data-content font-weight-bold">
      <div class="title">{{ isExpried ? "Expired" : "Expires" }} on</div>
      <div class="value text-dark">
        <!-- {{ (campaign?.giftPollExpiryDate | date: "dd-MM-yyyy") || "n/a" }} -->
        {{ (campaign?.collectionDeadline | date : "dd-MM-yyyy") || "n/a" }}
      </div>
    </div>
    <div
      class="pt-2"
      *ngIf="
        campaign.status == 'closed' && campaign?.templateType != 'freeCard'
      "
    >
      <!-- *ngIf="campaign?.finalTemplate && campaign.isAmountProcessed" -->
      <a
        style="color: #ef4f60"
        (click)="$event.stopPropagation()"
        href="/digital-e-card/{{ campaign?.inviteLink | campaignInviteId }}"
        target="_blank"
        >VIEW CARD</a
      >
    </div>
    <div class="data-content text-center">
      <div class="contributors">
        <div
          *ngFor="
            let item of campaign.contributions | slice : 0 : 3;
            let i = index
          "
        >
          <img
            height="30px"
            [tooltip]="
              item?.user
                ? (item?.user?.firstName | titlecase)
                : item?.contributorEmail
            "
            class="rounded-circle"
            [src]="item?.user?.imgUrl"
            onerror="this.src = 'assets/images/team-man-placeholder.jpg'"
            alt=""
          />
        </div>
        <div *ngIf="campaign?.contributions?.length > 3">
          <span [tooltip]="'+' + (campaign?.contributions?.length - 3)"
            >+{{ campaign?.contributions?.length - 3 }}</span
          >
        </div>
        <!-- <div *ngIf="!campaign?.contributions?.length">
          <span tooltip="N/a">N/a</span>
        </div> -->
      </div>

      <div class="value font-weight-bold text-dark">
        {{ campaign?.contributions?.length || 0 }} Contribution{{
          campaign?.contributions?.length > 1 ? "s" : ""
        }}
      </div>
    </div>
  </footer>
</mat-card>

<ng-template #suggestionFromContributors>
  <div class="suggestion-container">
    <button mat-icon-button (click)="dialogRef.close()" class="float-right">
      <mat-icon>clear</mat-icon>
    </button>
    <header>
      <h4>Suggestions From Contributors</h4>
    </header>
    <div class="suggestions">
      <div *ngFor="let item of campaign.contributorGiftIdeas; let i = index">
        <header
          class="mt-3"
          *ngIf="item?.userId !== campaign?.contributorGiftIdeas[i - 1]?.userId"
        >
          <h5 class="mb-0">
            {{ item?.user?.firstName | titlecase }} {{ item?.user?.lastName }}
          </h5>
        </header>
        <div class="ml-3 url">
          {{ item?.url }}
        </div>
      </div>
    </div>
  </div>
</ng-template>
