import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Template } from './interfaces/layout.type';
import { LayoutContentItemComponent } from './components/layout-content-item/layout-content-item.component';

@Component({
  selector: 'app-design-own-card',
  templateUrl: './design-own-card.component.html',
  styleUrls: ['./design-own-card.component.scss'],
})
export class DesignOwnCardComponent implements OnInit, OnChanges {
  selectedTemplateId: string;
  selectedTemp: Template;
  @Input() cardRatio: number;
  @Input() cardHeight: number;
  @Input() cardWidth: number;
  @Output() onGenerateImage = new EventEmitter<{ file: File }>();
  templates: Template[] = [
    {
      id: 'template-1',
      name: 'Template 1',

      config: {
        gapSize: 10,
        containerWidth: '200px',
        backgroundColor: '#fbae2c',
        layout: [
          [
            {
              width: '100%',
              height: '50%',
              type: 'text',
            },
          ],
          [
            {
              height: '50%',
              width: '100%',
              type: 'image',
            },
          ],
        ],
      },
    },
    {
      id: 'template-2',
      name: 'Template 2',

      config: {
        gapSize: 10,
        containerWidth: '200px',
        backgroundColor: '#fbae2c',
        layout: [
          [
            {
              width: '50%',
              height: '40%',
              type: 'image',
            },
            {
              width: '50%',
              height: '40%',
              type: 'image',
            },
          ],
          [
            {
              height: '20%',
              type: 'text',
              colspan: 2,
            },
          ],
          [
            {
              width: '50%',
              height: '40%',
              type: 'image',
            },
            {
              width: '50%',
              height: '40%',
              type: 'image',
            },
          ],
        ],
      },
    },
    {
      id: 'template-3',
      name: 'Template 3',

      config: {
        gapSize: 10,
        containerWidth: '200px',
        backgroundColor: '#fbae2c',
        layout: [
          [
            {
              width: '50%',
              height: '40%',
              type: 'image',
            },
            {
              width: '50%',
              height: '40%',
              type: 'image',
            },
          ],
          [
            {
              type: 'text',
              height: '20%',
              colspan: 2,
            },
          ],
          [
            {
              height: '40%',
              type: 'image',
              colspan: 2,
            },
          ],
        ],
      },
    },
    {
      id: 'template-4',
      name: 'Template 4',

      config: {
        gapSize: 10,
        containerWidth: '200px',
        backgroundColor: '#fbae2c',
        layout: [
          [
            {
              height: '40%',
              type: 'image',
            },
          ],
          [
            {
              height: '20%',
              type: 'text',
            },
          ],
          [
            {
              height: '40%',
              type: 'image',
            },
          ],
        ],
      },
    },
    {
      id: 'template-5',
      name: 'Template 5',

      config: {
        gapSize: 10,
        containerWidth: '200px',
        backgroundColor: '#fbae2c',
        layout: [
          [
            {
              type: 'text',
              height: '30%',
            },
          ],
          [
            {
              height: '70%',
              type: 'image',
            },
          ],
        ],
      },
    },
    {
      id: 'template-6',
      name: 'Template 6',

      config: {
        gapSize: 10,
        containerWidth: '200px',
        backgroundColor: '#fbae2c',
        layout: [
          [
            {
              type: 'text',
            },
          ],
        ],
      },
    },
    {
      id: 'template-7',
      name: 'Template 7',

      config: {
        gapSize: 10,
        containerWidth: '200px',
        backgroundColor: '#fbae2c',
        layout: [
          [
            {
              type: 'image',
            },
          ],
        ],
      },
    },
  ];
  @ViewChild(LayoutContentItemComponent)
  contentItem: LayoutContentItemComponent;
  isContentStyleVisible = false;

  constructor(private cdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.selectTemplateId(this.templates[0].id);
    this.selectTemplate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cardWidth && this.selectedTemp) {
      this.selectedTemp.config.containerWidth = this.cardWidth + 'px';
    }
  }

  selectTemplateId(templateId: string) {
    this.selectedTemplateId = templateId;
  }

  selectTemplate(templateId: string = this.selectedTemplateId) {
    const temp = this.templates.find((t) => t.id === templateId);
    if (!temp) {
      return;
    }
    this.selectedTemp = JSON.parse(JSON.stringify(temp));
    this.selectedTemp.config.containerWidth = (this.cardWidth || 441) + 'px';

    this.selectedTemp.config.gapSize = 20;
    this.cdRef.detectChanges();
    this.isContentStyleVisible = this.selectedTemp.config.layout.some((l) =>
      l.some((i) => i.type === 'text')
    );
  }

  getContainerStyle(temp: Template) {
    const width = temp.config.containerWidth;
    const height = this.calculateHeight(width, this.cardRatio);
    return {
      width: width,
      height: height,
      backgroundColor: temp.config.backgroundColor,
    };
  }

  calculateHeight(width: string, ratio: number): string {
    const numericWidth = parseFloat(width);
    const unit = width.replace(/[\d.]/g, '');
    const height = numericWidth / ratio;
    return `${height}${unit}`;
  }

  generateImage(container: HTMLDivElement) {
    const collageContainer = container.children[0]! as HTMLDivElement;
    var canvas = document.createElement('canvas');
    canvas.width = collageContainer.offsetWidth * 2;
    canvas.height = collageContainer.offsetHeight * 2;
    var context = canvas.getContext('2d')!;
    var doc = document.implementation.createHTMLDocument('');
    collageContainer.style.transform = 'scale(2) translate(24.1%,24.3%)';
    doc.write(container.innerHTML);

    collageContainer.style.transform = '';
    doc.documentElement.setAttribute(
      'xmlns',
      doc?.documentElement?.namespaceURI!
    );
    const html = new XMLSerializer().serializeToString(doc.body);
    var data =
      'data:image/svg+xml;charset=utf-8,' +
      '<svg xmlns="http://www.w3.org/2000/svg" width="' +
      collageContainer.offsetWidth * 2 +
      '" height="' +
      collageContainer.offsetHeight * 2 +
      '">' +
      '<foreignObject width="100%" height="100%">' +
      html +
      '</foreignObject>' +
      '</svg>';
    var img = new Image();
    img.onload = () => {
      context.drawImage(img, 0, 0);
      canvas.toBlob((blob) => {
        if (blob) {
          const file = new File([blob], 'cover-image.png', {
            type: 'image/png',
          });
          this.onGenerateImage.emit({ file });
        }
      });
    };
    img.src = data;
  }
}
