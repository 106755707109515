<form
  [formGroup]="myFormGroup"
  (ngSubmit)="submitForm()"
  style="border-collapse: collapse"
>
  <div class="form-wrapper">
    <div class="header">
      <h1 class="title">Payout</h1>
    </div>

    <div class="input-group">
      <label class="input-label">Card Number</label>
      <input
        id="card_number"
        (input)="valueChanges($event.target.value)"
        type="number"
        maxlength="16"
        class="form-control"
        placeholder="Enter card number"
        formControlName="card_number"
      />
      <control-messages
        [control]="myFormGroup.get('card_number')"
      ></control-messages>
    </div>
    <div class="input-group">
      <label class="input-label">Card Exp</label>
      <input
        id="card_exp"
        (input)="valueChanges($event.target.value)"
        type="text"
        maxlength="5"
        class="form-control"
        placeholder="MM/YY"
        formControlName="card_exp"
      />
    </div>
    <div class="input-group">
      <label class="input-label">Email</label>
      <input
        id="email"
        (input)="valueChanges($event.target.value)"
        type="email"
        class="form-control"
        placeholder="Enter email"
        formControlName="email"
      />
    </div>

    <div class="input-group">
      <label class="input-label">Amount</label>
      <input
        id="amount"
        (input)="valueChanges($event.target.value)"
        type="number"
        value=""
        maxlength="10"
        class="form-control"
        placeholder="Enter amount"
        formControlName="amount"
      />
    </div>

    <div class="button-group">
      <button
        mat-ripple
        size="lg"
        ButtonUi
        color="c1"
        class="pay-button"
        type="submit"
        [disabled]="myFormGroup.invalid"
      >
        PAY NOW
      </button>
    </div>
  </div>
</form>
