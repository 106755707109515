<div class="dialog-container p-0">
  <header class="border-bottom p-3 d-flex align-items-center">
    <div class="flex-header">
      <h5 class="m-0">
        {{
          data?.viewOnly
            ? "Browse available Gift Providers (you can select your provider in the coming steps)"
            : data?.productId?.isPayout
            ? "Payout funds to digital MasterCard"
            : "Please choose your Gift Provider here"
        }}
      </h5>
    </div>
    <div class="ml-auto align-center">
      <input
        class="form-control"
        type="search"
        [(ngModel)]="searchVal"
        (input)="search()"
      />
      <button mat-icon-button (click)="dialogRef.close()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </header>

  <div class="content">
    <div class="row w-100">
      <!-- [class.d-none]="checkIsInMaxMin(item)" -->
      <div class="col-md-3" *ngFor="let item of fullTremendousList">
        <mat-card
          class="mb-3"
          (click)="catalogueCard(item, data?.viewOnly)"
          [class.selected]="item.product_id === selectedProduct?.product_id"
        >
          <img mat-card-image [src]="item?.image_url" />
          <mat-card-content>
            <p class="text-center h5">
              {{ item?.name }}
            </p>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="align-center justify-center w-100">
        <!-- Print Card -->
        <mat-spinner
          class="d-inline-block ml-2"
          *ngIf="tremendousProducts?.isLoading"
          mode="indeterminate"
          [strokeWidth]="2"
          [diameter]="22"
        ></mat-spinner>
      </div>
    </div>

    <div
      class="w-100 col-12 text-center"
      *ngIf="
        !tremendousProducts?.list?.length && !tremendousProducts?.isLoading
      "
    >
      No data is available
    </div>
  </div>

  <footer class="text-right border-top p-3" *ngIf="!data.viewOnly">
    <button
      *ngIf="!data?.multiple"
      matRipple
      size="lg"
      color="c1"
      [loading]="isLoading || tremendousProducts?.isLoading"
      [disabled]="!selectedProduct?.id || isLoading"
      (click)="done()"
      ButtonUi
    >
      Confirm Selection
    </button>
    <!-- <button
      *ngIf="data?.multiple"
      size="lg"
      color="c1"
      (click)="back()"
      ButtonUi
    >
      Select More
    </button> -->
  </footer>
</div>
