import {
  Component,
  HostBinding,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { LayoutItem } from '../../interfaces/layout.type';

@Component({
  selector: 'layout-content-item',
  templateUrl: './layout-content-item.component.html',
  styleUrls: ['./layout-content-item.component.scss'],
})
export class LayoutContentItemComponent implements OnInit {
  @Input() item: LayoutItem;
  @ViewChild('dialogTemp') dialogTemp: TemplateRef<HTMLDivElement>;
  message: string = 'Message here...';
  textStyles = {
    fontSize: 20,
    color: 'black',
    fontWeight: 'normal',
    fontFamily: 'Source Sans Pro, sans-serif',
    lineHeight: 1.5,
    textAlign: 'center',
  };

  readonly fontFamilies = [
    'Source Sans Pro, sans-serif',
    'Caviar Dreams',
    'GeosansLight',
    'fantasy',
    'inherit',
    'initial',
    'monospace',
    'sans-serif',
    'unset',
  ];

  constructor() { }

  ngOnInit() { }

  get fontSize() {
    return this.textStyles.fontSize
  }

  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }
    return value;
  }

  @HostBinding('style') get style() {
    return {
      display: 'block',
      width: '100%',
      height: '100%',
    };
  }
}
