<div class="info-text">
  click on card to open and click on each page to turn page
</div>
<div class="book-box">
  <div
    class="flip-book"
    [style.width]="cardDimensions.width"
    [style.height]="cardDimensions.height"
    [class.opened]="isAnyPageFlipped()"
  >
    <div
      class="page"
      *ngFor="let page of pages; let i = index"
      [class.flipped]="page.flipped"
      [style.zIndex]="page.flipped ? i + 1 : pages.length - i"
      (click)="flipPage(i)"
    >
      <div class="front">
        <img
          *ngIf="page?.frontPage?.image"
          appImage
          [src]="page?.frontPage?.image"
        />
        <div class="content" [class.show]="page?.frontPage?.opened">
          <p *ngFor="let item of page?.frontPage?.wishes" [ngStyle]="item">
            <span style="background-color: transparent">{{
              item.message
            }}</span>
          </p>
        </div>
      </div>
      <!-- <div class="back">
        <img
          *ngIf="page?.backPage?.image"
          appImage
          #image="appImage"
          [src]="page?.front?.image"
        />
      </div> -->
      <div class="content" [class.show]="page?.backPage?.opened">
        <p
          style="transform: rotateY(180deg)"
          *ngFor="let item of page?.backPage?.wishes"
          [ngStyle]="item"
        >
          <span
            class="gift-card-message"
            style="background-color: transparent"
            >{{ item.message }}</span
          >
        </p>
      </div>
    </div>
  </div>
</div>
