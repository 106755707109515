<div
  style="text-align: center; outline: none"
  (keypress)="message = $event.target.value"
  [attr.contenteditable]="true"
  [ngStyle]="textStyles"
  [style.fontSize]="textStyles.fontSize + 'px'"
>
  {{ message }}
</div>

<ng-template #dialogTemp>
  <h4>Message Styles</h4>
  <div class="mb-3">
    <label>Font Family</label> <br />
    <select class="w-100 mb-3 p-2" [(ngModel)]="textStyles.fontFamily">
      <option *ngFor="let font of fontFamilies" [value]="font">
        {{ font }}
      </option>
    </select>
  </div>
  <div class="mb-3">
    <div class="d-flex justify-content-between">
      <label for="font-size">Font Size</label>
      <label class="value">{{ fontSize }}</label>
    </div>
    <input
      id="font-size"
      type="range"
      class="w-100 font-size-input"
      min="10"
      max="50"
      [(ngModel)]="textStyles.fontSize"
      [ngModelOptions]="{ standalone: true }"
      #fontSizeInput
    />
  </div>
  <div class="mb-3">
    <label for="font-color">Font color</label> <br />
    <input
      id="font-color"
      type="color"
      class="w-100"
      matInput
      [(ngModel)]="textStyles.color"
    />
  </div>
  <div class="mb-3">
    <label>Font Weight</label> <br />
    <mat-button-toggle-group [(value)]="textStyles.fontWeight">
      <mat-button-toggle value="normal" aria-label="Text align left">
        Normal
      </mat-button-toggle>
      <mat-button-toggle value="bold" aria-label="Text align center">
        Bold
      </mat-button-toggle>
      <mat-button-toggle value="bolder" aria-label="Text align right">
        Bolder
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="mb-3">
    <label>Text Align</label> <br />
    <mat-button-toggle-group [(value)]="textStyles.textAlign">
      <mat-button-toggle value="left" aria-label="Text align left">
        <mat-icon>format_align_left</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle value="center" aria-label="Text align center">
        <mat-icon>format_align_center</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle value="right" aria-label="Text align right">
        <mat-icon>format_align_right</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</ng-template>
