import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CreateCatalogueProductAndProceedComponent } from '../../components/create-catalogue-product-and-proceed/create-catalogue-product-and-proceed.component';
import { AppService } from 'src/app/services/app.service';
import { SelectedProductsArray, TremendousProduct } from 'src/app/models/tremendous.product.model';
import { Campaign } from 'src/app/models/campaign.model';
import { SuccessMessageDialogComponent } from 'src/app/modules/shareModule/dialogs/success-message-dialog/success-message-dialog.component';
import { Router } from '@angular/router';
import { CampaignService } from 'src/app/services/campaign.service';

@Component({
  selector: 'app-charity-dialog',
  templateUrl: './charity-dialog.component.html',
  styleUrls: ['./charity-dialog.component.scss']
})
export class CharityDialogComponent implements OnInit {
  selectedProducts: SelectedProductsArray[] = [
    { item: null, amount: 0, },
    { item: null, amount: 0, },
    { item: null, amount: 0, },
    { item: null, amount: 0, }];
  multiple: true;

  selectedProduct: TremendousProduct;
  isLoading: boolean = false;
  selectedProductIndex: number;
  enteredAmount: number = 0;
  @Input() campaign: Campaign = new Campaign();
  @Output() campaignChange: EventEmitter<Campaign> = new EventEmitter();
  constructor(
    public _appService: AppService,
    private _campaignService: CampaignService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CharityDialogComponent>,
    public _matDialog: MatDialog,
    private _router: Router,
    @Inject(MAT_DIALOG_DATA) public data: { data: any, campaign: Campaign }
  ) {

  }

  ngOnInit(): void { console.log(this.data); }

  callFunction() {
    const viewOnly = false;
    const multiple = false;
    this.chooseByRetailer(viewOnly, multiple);
  }

  public isMultiple = false;
  public max_value = false;

  setMultiple() {
    this.isMultiple = !this.isMultiple
  }

  onInputChange(e: any, i: number) {
    if (this.totalCardAmount > this.charityAmount()) {
      this._appService.snackBarOpen("You do not have sufficient funds, please review the amount you would like to spend on each retailer.");
      this.max_value = true

      e.target.value -= this.totalCardAmount - this.charityAmount();
      this.selectedProducts[i].amount = Number(e.target.value)
    } else {
      this.max_value = false
    }
  }

  get totalCardAmount() {
    return this.selectedProducts.reduce((pv, cv) => cv.amount + pv, 0);
  }

  chooseByRetailer(viewOnly: boolean, multiple?: boolean, i?: number) {
    this._appService.matDialog
      .open(CreateCatalogueProductAndProceedComponent, {
        maxHeight: '100vh',
        maxWidth: '100vw',
        width: '90vw',
        height: '90vh',
        data: {
          data: { giftAmount: this.selectedProducts[i]?.amount },
          isSingleToProceed: !multiple,
          campaign: {
            ...this.data?.campaign,
            ...this.data?.data.this.form?.value,
            cardAmount: this.data?.data.this?.campaignCardPr,
            charityAmount: this.data?.data.this?.campaignCardPr - this.data?.campaign.collectedAmount,
          },
          remainingAmount: this.data.campaign.remainingAmount,
          viewOnly,
          multiple
        },
      })
      .afterClosed()
      .subscribe((d) => {
        console.log(d);
        if (i != null) {
          this.selectedProducts[i].item = d.data;
          this.selectedProducts[i].amount = this.selectedProducts[i]?.amount;
        }
        this.selectedProduct = d.data

        console.log(d);
        if (d.isSingleToProceed) {
          if (d) {
            this.data.campaign = { ...this.data.campaign, ...d };
            this.data.campaign.isAmountProcessed = true;
            this.campaignChange.emit(this.data.campaign);
            // this.createProdigiOrder();
            // Object.keys(this.form.controls).forEach((d) =>
            //   this.form.get(d).disable()
            // );
            this.successDialog();
          }
          this.dialogRef.close()
        }

      });
  }

  // createProdigiOrder() {
  //   if (this.form.get('cardType').value === 'prodigi') {
  //     this.prodigiForm.createOrder();
  //   }
  // }

  successDialog() {
    const catalogueTitle = `Thank You for redeeming your CollectAGift Pot for ${this.data?.campaign.receipientName}. Your gift voucher will be emailed to your registered email address (check your junk mail if you can’t find it) and you will be able to share this directly with ${this.campaign.receipientName} for them to redeem accordingly. Thank you for using CollectaGift.`;
    const title = `Thank You for redeeming your CollectAGift Pot for ${this.data?.campaign.receipientName}. Your digital Visa debit card will be emailed to your registered email address (check your junk mail if you can’t find it) and you will be able to redeem it anywhere online where a visa debit card is accepted. Thank you for using CollectaGift`;
    this._matDialog
      .open(SuccessMessageDialogComponent, {
        data: {
          title:
            this.data?.campaign.payoutType == 'catalogue' ? catalogueTitle : title,
        },
      })
      .afterClosed()
      .subscribe((d) => {
        this._router.navigate(['/pages/detail/campaigns']);
      });
  }

  get isSelectedProducts() {
    return this.selectedProducts.some(product => product.item !== null);
  }


  async done() {
    this.isLoading = true;
    let body = {
      id: this.data.campaign.id,
      purchaseCard: true,
      charityAmount: this.data.campaign.collectedAmount - this.data.campaign.cardAmount,
      cardAmount: this.data.campaign.cardAmount,
      comment: this.data.campaign.comment,
      userAmount: this.data.campaign.userAmount,
      cardRecipientEmail: this.data.campaign.cardRecipientEmail,
      campaignCurrency: this.data.campaign.campaignCurrency,
      charityId: this.data.campaign.charityId,
      giftAmount: null,
      // automaticCardEmail: this.data.campaign.automaticCardEmail,
      cardEmailTime: this.data.campaign.cardEmailTime,
      receipientEmail: this.data.campaign.cardRecipientEmail,
      stripeCharges: this.data.campaign.stripeCharges,
      templateType: this.data.campaign.templateType,
      selectedTemplateUrl: this.data.campaign.selectedTemplateUrl,
      isAmountProcessed: this.data.campaign.isAmountProcessed,
      productId: null,
      giftCardList: []
    };
    if (this.campaign.templateType == 'freeCard') {
      body.cardAmount = 0;
      body.giftAmount = this.data.campaign.remainingAmount;
    }
    body.charityAmount = this.data.campaign.collectedAmount - this.data.campaign.cardAmount
    body.receipientEmail = this.data.campaign.cardRecipientEmail

    if (this.isMultiple) {
      const amount = this.selectedProducts.map((list) => list?.amount)
      const products = this.selectedProducts.map((list) => list?.item?.items[0]?.code)
      body.giftAmount = this._campaignService.removeEmptyGiftCards(amount)
      body.productId = this._campaignService.removeEmptyGiftCards(products)
    } else {
      body.productId = this.selectedProduct.items[0].code
      body.giftAmount = this.data.campaign.remainingAmount;
    }

    console.log(body);

    const data = await this._campaignService.RedeemDonate(body);
    if (!data) {
      return (this.isLoading = false);
    }
    this.isLoading = false;
    const isSingleToProceed = true
    this.dialogRef.close({ data, isSingleToProceed });
    this.successDialog();
  }

  charityAmount() {
    return this.data?.campaign?.collectedAmount - this?.data.data?.this.campaignCardPr

  }

}
